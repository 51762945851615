<template>
	<!-- nav -->
	<nav class="nav">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev" @click="goBack">
				<i class="icon icon-left">이전 페이지</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					최근 본 상품
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<!-- // nav -->
	<div id="container" class="container container--fit">
		<!-- [REST] 전체 내용 관리자 페이지에서 설정 -->
		<div id="sub" class="sub mypage">
			<!-- content -->
			<section class="content" v-if="recentData.length > 0">
				<div class="cart__top">
					<div class="item">
						<div class="allcheck">
							<div class="allcheck__all">
							</div>
							<button type="button" class="btn-delete" @click="onClickRemoveAll">
								전체삭제
							</button>
						</div>
					</div>
				</div>
				<ul class="cart__list cart__list--large">
					<li v-for="item in recentData" :key="item.SEQ">
						<router-link :to="`/rooms/${item.HOTEL_NO}/${item.PROD_NO}`">
							<div class="item">
								<div class="cart__wrap">
									<div class="cart__item">
										<div class="cart__thumbnail noneimage">
											<div class="imagebg" :style="{'background-image': 'url(\'' + item.IMG_URL + '\')'}" />
										</div>
										<!-- summary item -->
										<div class="cart__itembox">
											<div class="cart__itemdata">
												<p class="cart__option info-slogan">
													{{item.HOTEL_TYPE}}
												</p>
												<h3 class="cart__subtitle info-title multiline">
													{{item.HOTEL_NM}}
												</h3>
												<div class="info-distance ellipsis" >
													{{item.MHOTEL_LOCTEXT}}
												</div>
											</div>
											<ul class="cart__info">
												<li>
													<div class="list__info-type">
														<span class="list__badge">{{item.PROD_TYPE}}</span>
													</div>
													<div class="list__info-price info-price">
														<div class="info-price__current">
															<strong>{{item.PROD_AMT.toLocaleString("en-US")}}</strong>원
														</div>
													</div>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</router-link>
					</li>
				</ul>
			</section>
			<section class="content" v-else>
				<div class="centered centered--large">
					<div class="centered__wrap">
						<div class="textbox textbox--margin">
							<div class="textbox__icon">
								<img src="@/assets/images/img_warning.png" alt="찾으시는 결과가 없나요?" style="width:64px">
							</div>
							<h3 class="textbox__title">
								최근 본 숙소가 없어요
							</h3>
							<p class="textbox__description">
								 다양한 숙소를 만나고 <br>
								지금 숙소를 예약해 보세요!
							</p>
							<div class="textbox__button">
								<div class="row">
									<router-link to="/rooms">
										<button type="button" class="btn btn-primary purple">
											숙소 보러가기
										</button>
									</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
	<HistoryDelete v-if="openMessage" :handleClose="()=> openMessage = false" :handleSubmit="historyDeleteAction"/>
</template>

<script>
import { useStore } from "vuex"
import { computed, onMounted, ref } from "vue"
import { useRouter } from 'vue-router';
import HistoryDelete from '@/components/layers/Message_historydelete';
import apiMmy01 from "@/urls/mmy01";
import apiMmz01 from "@/urls/mmz01";

export default {
	components: {
		HistoryDelete
	},
	setup: function() {
		const store = useStore();
		const router = useRouter();

		const userData = computed(() => store.state.userData);
		const isLogin = computed(() => Object.keys(userData.value).length !== 0);
		const sessionId = computed(() => store.state.sessionId);
		const mmy0111 = computed(() => store.state.mmy01.mmy0111);
		const mmz0111 = computed(() => store.state.mmz01.mmz0111);

		const recentData = computed(()=> isLogin.value? mmy0111.value : mmz0111.value);

		const openMessage = ref(false);
		const historyDeleteAction = ref(async () => {});

		onMounted(()=>{
			loadRecentData();
		});

		const loadRecentData = () => {
			if(isLogin.value) {
				store.dispatch("mmy01/fetchMmy0111", {
					proc_cd: "01",
					mem_id: userData.value.mem_token
				});
			}
			else {
				store.dispatch("mmz01/fetchMmz0111", {
					proc_cd: "01",
					session_id: sessionId.value
				});
			}
		};

		const removeAllRecentData = async () => {
			openMessage.value = true;
			historyDeleteAction.value = async () => {
				if(isLogin.value) {
					const data = await apiMmy01.getMmy0111({
						proc_cd: "99",
						mem_id: userData.value.mem_token
					});
					if(data.data.Res_cd === "0000") {
						store.commit("mmy01/SET_MMY0111", { mmy0111: [] });
					}
				}
				else {
					const data = await apiMmz01.getMmz0111({
						proc_cd: "99",
						session_id: sessionId.value
					});
					if(data.data.Res_cd === "0000") {
						store.commit("mmz01/SET_MMZ0111", { mmz0111: [] });
					}
				}
				openMessage.value = false;
			};
		};

		const onClickRemoveAll = async () => {
			await removeAllRecentData();
			loadRecentData();
		}

		return {
			userData,
			isLogin,
			recentData,
			onClickRemoveAll,
			goBack: () => {
				router.back();
			},
			historyDeleteAction,
			openMessage
		}
	}
}
</script>